.sweepOrderContentBX{
    min-height: calc(100vh - 190px);
}
.unActiveServiceFooterBx{
    width: 100%;
    position: absolute;
    bottom: 20px;
}
.unActiveServiceBx{
    margin: 0 5%;
    position: relative;
    height: 95%;
    width: 90%;
    top: 5%;
}
.unActiveService{
    display: flex;
    height: 90%;
    justify-content: center;
    align-items: center;
    font-size: 2.2rem;
}