.placeOrderPage{
  background-color: #f2f2f2;
  height: 100%;
  overflow: auto;
  .placeOrderFooterBx{
	  padding-bottom: 10px;
  }
}

@media screen and (min-width: 769px){
  .orderDetailContainer{
  	min-height: calc(100% - 60px);
  }
}

@media screen and (min-width:1200px){
	.orderDetailContainer{
		padding: 0 54px;
	}
}
