@import "../../constants/orderItem.scss";
.sendToKitchenPage{
	height: 100%;
	overflow: auto;
	padding: 16px 16px 150px;
	background-color: #e5e5e5;
	max-width: 800px;
	margin: 0 auto;
	
	.orderItemsContainer{
		background-color: #fff;
		border-radius: 10px;
		margin-top: 20px;
		.itemInfoRight{
			display: flex;
			align-items: center;
			.itemPrice,.beforeDiscountPrice{
				font-size: 12px;
			}
		}
	}

	.subtotalCon{
		height: 30px;
		line-height: 30px;
		position: relative;
		margin-top: 10px;
		.line{
			height: 15px;
			border-bottom: 1px dashed #c0c2c8;
		}
		.subtotal{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			line-height: 30px;
			text-align: center;
			font-size: 15px;
			padding: 0 6px;
			span{
				background-color: #e5e5e5;
				padding: 0 10px;
				color: #737375;
			}
		}
	}
		
	.bottomBtns{
		max-width: 800px;
		margin: 0 auto;
		height: 138px;
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 12px 16px 0;
		font-size: 18px;
		background-color: rgba(229,229,229,0.5);
		.addMoreBtn{
			height: 45px;
			line-height: 45px;
			background-color: var(--theme-color);
			text-align: center;
			border-radius: 10px;
			margin-bottom: 15px;
			box-shadow: 0 0 12px rgba(0,0,0,0.2);
			color: var(--color-btn-text);
			font-weight:600;
			cursor: pointer;
		}
		.checkoutBtn{
			cursor: pointer;
			height: 41px;
			line-height: 41px;
			border: 2px solid #49494c;
			background-color: #fff;
			color: var(--theme-text-color);
			text-align: center;
			border-radius: 10px;
			font-weight:600;
			box-shadow: 0 0 12px rgba(0,0,0,0.2);
		}
	}
}
