.rewardPage{

	letter-spacing: 0;
	height: 100%;
	display: flex;
	flex-direction: column;

	.header{
		background-color: #120136;
		height: 50px;
		color: #fff;
		.nav{
			max-width: 1000px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 20px 0 5px;
			.backIcon{
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				i{
					font-size: 20px;
				}
			}
			.history{
				font-size: 12px;
				background-color: rgba(255,255,255,0.2);
				padding: 0 8px;
				height: 24px;
				display: flex;
				align-items: center;
				border-radius: 5px;
				cursor: pointer;
				span{
					padding-right: 5px;
				}
				img,i{
					font-size: 14px;
					width: 14px;
					height: 14px;
				}
			}
		}
	}
	
	.mainCon{
		height: 0;
		flex: 1;
		overflow: auto;
		
		.currentPointOut{
			background-color: #120136;
			.currentPoint{
				color: #fff;
				max-width: 800px;
				margin: 0 auto;
				position: relative;
				padding-top: 20px;
				.restaurantName{
					font-size: 20px;
					font-weight: bold;
					line-height: 26px;
					padding: 0 30px;
					color: rgba(255,255,255,0.7);
					text-align: center;
				}
				.points{
					display: flex;
					align-items: center;
					justify-content: center;
					color: #fff;
					height: 50px;
					margin-top: 12px;
					.star{
						width: 27px;
						height: 27px;
					}
					.num{
						font-size: 40px;
						font-weight: bold;
						padding: 0 10px 0 6px;
					}
					.unit{
						font-size: 14px;
					}
				}
				.exp{
					text-align: center;
					color: rgba(255,255,255,0.7);
					font-size: 12px;
					line-height: 30px;
					min-height: 10px;
				}
				.star0{
					position: absolute;
					font-size: 10px;
					display: block;
				}
				.star1{
					top: 15px;
					left: 65px;
				}
				.star2{
					right: 73px;
					bottom: 30px;
				}
			}
		}
		.currentMemberTier{
			padding: 24px 8px 0;
			background-color: #120136;		
			.memberCard{
				margin: 0 auto;
				max-width: 800px;
				padding: 22px;
				border-radius: 20px;
				background-color: #FFF;
				.memberTitle{
					font-size: 18px;
					font-weight: 600;
				}
				.down{
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-top: 20px;
					.memberLevel{
						font-size: 30px;
						font-weight: 600;
						line-height: 44px;
					}
					.viewTierDetail{
						height: 28px;
						line-height: 22px;
						border-radius: 10px;
						border: 1px solid #120136;
						color: #120136;
						text-align: center;
						font-size: 12px;
						padding: 2px 10px;
						cursor: pointer;
					}
				}
			}	
		}
		
		.sectionOut{
			padding: 15px 8px 24px;
			position: relative;
			&::before{
				content: " ";
				background-color: #120136;
				top: 0;
				left: 0;
				right: 0;
				height: 100px;
				position: absolute;
				z-index: -1;
			}
			.section{
				border-radius: 20px;
				max-width: 800px;
				margin: 0 auto;
				background-color: #fff;
				box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
				
				.mainTitle{
					display: flex;
					align-items: center;
					height: 55px;
					padding-left: 22px;
					max-width: 1080px;
					margin: 0 auto;
					img{
						width: 16px;
						height: 16px;
					}
					span{
						font-size: 18px;
						font-weight: 600;
						padding-left: 4px;
					}
				}
				
				&.rewardSection.widthBullet{
					position: relative;
					padding-bottom: 10px;
					.rewardPagination{
						position: absolute;
						bottom: 8px;
						left: 50%;
						transform: translateX(-50%);
						height: 5px;
						display: flex;
						align-items: center;
						justify-content: center;
						.rewardBullet{
							display: block;
							width: 8px;
							height: 8px;
							margin: 0 4px;
							border-radius: 4px;
							background-color: rgba(0,0,0,0.2);
							&.rewardBulletActive{
								background-color: #ff823c;
							}
						}
					}
				}
				
				&.earnSection{
					.mybtn{
						margin-top: 35px;
					}
				}
                
                &.visitSection{
                    margin-top: 32px;
                    box-shadow: none;
                    padding: 10px 20px;
                    background: none;
                    .visitDesc{
                        flex: 1;
                        font-size: 14px;
                        color: #828282;
                        margin-bottom: 12px;
                    }
                    .visitBtn{
                        border-radius: 5px;
                        border: 1px solid #5984fc;
                        color: #5984fc;
                        font-size: 14px;
                        line-height: 28px;
                        height: 28px;
                        padding: 0 12px;
                        cursor: pointer;
                    }
                }
			}
			
			.rewardsCon{
				max-width: 1080px;
				margin: 0 auto;
				padding-bottom: 16px;
				.rewards{
					display: flex;
					padding: 0 8px;
					.rewardItemCon{
						padding: 0 8px;
					}
				}
			}
			
			.rewardItem{
				border: 1px solid #f2f2f2;
				border-radius: 16px;
				padding: 10px 15px 12px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				cursor: pointer;
				height: 100%;
				
				&:hover{
					box-shadow:  0 2px 4px rgba(0,0,0,0.1);
				}
				
				.infos{
					.title{
						display: flex;
						align-items: stretch;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
						letter-spacing: -0.02em;
						color: #120136;
						.name{
							line-height: 22px;
							text-overflow: ellipsis;
							display:-webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;				
						}
						.arrowRight{
							font-size: 18px;
							float: right;
							width: 20px;
							display: flex;
							align-items: flex-end;
						}
					}
					.desc{
						font-size: 12px;
						color: #828282;
						line-height: 16px;
						margin: 5px 0;
						text-overflow: ellipsis;
						display:-webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;	
					}
				}
				
				.points{
					font-size: 12px;
					i{
						color: #FFB600;
						margin-right: 3px;
					}
					span{
						color: #120136;
					}
					.avaliable,.nums{
						align-items: center;
					}
					.percent{
						.line{
							margin-top: 3px;
							height: 8px;
							border-radius: 4px;
							background-color: #E0E0E0;
							.lineColor{
								background-color: #FFB600;
								border-radius: 4px;
								height: 8px;
							}
						}
					}
                    .voucherLabel{
                        font-size: 13px;
                        text-align: center;
                        line-height: 22px;
                        height: 22px;
                        color: #b38000;
                        background-color: #fff7e3;
                        margin-top: 6px;
                    }
				}
				&.singleItem{
					width: 100%;
					border: none;
					padding: 6px 34px;
				}
			}
			
			.earnSection{
				margin-top: 16px;
				padding-bottom: 25px;
				.desc{
					font-size: 12px;
					color: #828282;
					line-height: 17px;
					padding: 0 28px 0 34px;
					margin-top: -4px;
				}
				.earnItem{
					display: flex;
					align-items: center;
					height: 40px;
					margin-top: 6px;
					padding: 10px 16px 0 34px;
					.left{
						width: 40px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: #FDF6DF;
						border-radius: 20px;
						color: #FFB600;
						i{
							font-size: 22px;
						}
					}
					.mid{
						flex: 1;
						padding-left: 16px;
						.top{
							font-size: 16px;
							color: #120136;
						}
						.bottom{
							font-size: 14px;
							color: #828282;
						}
					}
					.right{
						height: 23px;
						line-height: 21px;
						border-radius: 10px;
						border: 1px solid #120136;
						color: #120136;
						text-align: center;
						font-size: 12px;
						padding: 0 10px;
						cursor: pointer;
					}
				}
				.pointsBag{
					width: 125.75px;
					display: block;
					margin: 20px auto;
				}
			}

		}
	}
	
	.popup{
		.popHeader{
			height: 68px;
			display: flex;
			align-items: center;
			padding: 0 20px;
			position: relative;
			&::after{
				content: " ";
				position: absolute;
				bottom: 0;
				left: 34px;
				right: 34px;
				height: 1px;
				background-color: rgba(0,0,0,0.1);
			}
			span{
				padding-left: 12px;
				font-weight: 600;
				font-size: 18px;
				flex: 1;
			}
			.backIcon{
				display: flex;
				align-items: center;
			}
			.closeIcon{
				width: 50px;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				i{
					font-size: 16px;
				}
				&:hover{
					i{
						font-size: 18px;
					}
				}
			}
		}
		.popMain{
			padding: 10px 20px 25px;
			.title{
				font-size: 16px;
				font-weight: 600;
				line-height: 30px;
				padding-top: 10px;
			}
			.option{
				position: relative;
				padding-left: 20px;
				margin-top: 20px;
				
				&::before{
					position: absolute;
					content: " ";
					width: 8px;
					height: 8px;
					border-radius: 4px;
					background-color: #FFB600;
					top: 7px;
					left: 3px;
				}
				color: #828282;
				font-size: 14px;
				line-height: 22px;
				h3{
					font-weight: 600;
					margin: 0 0 8px;
				}
				p{
					margin: 0 0 6px;
					display: flex;
					span{
						padding-left: 5px;
					}
					&.items{
						display:-webkit-box;
						overflow:hidden;
						-webkit-box-orient:vertical;
						-webkit-line-clamp:3;
						text-overflow:ellipsis;
					}
				}
			}
		}
		.popFooter{
			box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
			padding: 30px 24px 10px;
			i{
				color: #FFB600;
			}
			.points{
				display: flex;
				font-size: 18px;
				font-weight: 600;
				color: #120136;
				i{
					font-size: 22px;
				}
				.num{
					padding: 0 4px 0 6px;
				}
			}
			.desc{
				display: flex;
				align-items: center;
				font-size: 14px;
				padding: 10px 0;
				.pre{
					color: #828282;
					padding-right: 4px;
				}
				.num{
					padding: 0 3px;
					font-weight: bold;
				}
				.unit{
					font-weight: bold;
				}
			}
		}
		&.earnPopup,&.rewardPopup{
			.popMain{
				max-height: calc(90vh - 300px);
				overflow: auto;
			}
		}
		&.historyPopup{
			.history{
				min-height: 300px;
				max-height: calc(90vh - 160px);
				overflow-y: auto;
				padding: 10px 20px 20px;
				.historyItem{
					display: flex;
					align-items: center;
					height: 80px;
					.left{
						width: 40px;
						height: 40px;
						border-radius: 20px;
						background-color: #FDF6DF;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #FFB600;
						i{
							font-size:24px;
						}
					}
					.right{
						padding-left: 12px;
						flex: 1;
						.top{
							display:flex;
							.recordName{
								font-size: 16px;
								font-weight: 600;
								color: #120136;
								height: 26px;
								flex: 1;
							}
							.pointChange{
								display: flex;
								align-items: center;
								font-size: 16px;
								font-weight: 600;
								color: #120136;
								justify-content: flex-end;
								a{
									font-weight: normal;
									font-size: 18px;
								}
								span{
									padding-left: 4px;
								}
								i{
									color: #FFB600;
									font-size: 15px;
									margin-left: 5px;
								}
							}
						}
						.bottom{
							display:flex;
							align-items: center;
							.times{
								flex: 1;
								.time{
									font-size: 13px;
									color: #828282;
									line-height: 16px;
								}
							}
							.recordLabel{
								font-size: 14px;
								color: #828282;
							}
						}
					}
				}
				
				.empty{
					padding: 0 0 30px;
					text-align: center;
					h3{
						font-size: #120136;
						font-weight: 600;
						font-size: 16px;
						line-height: 22px;
					}
					p{
						font-weight: 400;
						font-size: 14px;
						line-height: 19px;
						padding: 0 50px 20px;
						color: #828282;
					}
				}
				
				.loading{
					text-align: center;
					padding: 5px;
					opacity: 0;
					&.show{
						opacity: 1;
					}
				}
			}
		}
	}

	@media screen and (min-width:481px){
		.mainCon .sectionOut{
            .earnSection{
                .earnItem{
                    height: 60px;
                    max-width: 600px;
                    margin: 20px auto;
                    border: 1px solid #f2f2f2;
                    border-radius: 15px;
                    padding: 15px 20px;
                }
            }
            .section.visitSection{
                display: flex;
                align-items: center;
                padding: 0;
                .visitDesc{
                    margin-bottom: 0;
                    margin-left: 12px;
                }
            }
			.rewardItem{
				&.singleItem{
					border: 1px solid #f2f2f2;
					width: 33%;
					margin: 0 16px;
				}
			}
        }
		.popup{
			min-width: 460px;
			max-width: 72vw;
			.popHeader{
				height: 60px;
				span{
					padding: 0;
				}
				.closeIcon{
					margin-right: -16px;
				}
				border-bottom: 1px solid rgba(0,0,0,0.1);
				&::after{
					display: none;
				}
			}
			&.earnPopup,&.rewardPopup{
				.popMain{
					max-height: calc(80vh - 250px);
					overflow: auto;
				}
			}
		}
		.popup.historyPopup .history{
			max-height: 72vh;
			.historyItem{
				width: 400px;
				padding: 0 5px;
				margin: 0 auto;
			}
		}
	}
}